/**
 * Site header
 */
.site-header {
  border-top: 5px solid $grey-color-dark;
  border-bottom: 1px solid $grey-color-light;
  min-height: $spacing-unit * 1.865;

  // Positioning context for the mobile navigation icon
  position: relative;
}

h1, h2, h3, h4 {
  color: $brand-color;
}

hr {
	height: 48px;
	width: 50%;
	min-width: 200px;
	margin: 24px auto;
	/* padding: 9px 0 24px 0; */
	background: url(/assets/separator.svg) no-repeat center center;
	background-size: 100% auto;
	border: none;
}

blockquote {
  color: #b5bd68;
  border-left: none;
  padding-left: 0;
  font-size: inherit;
  letter-spacing: unset;
  font-style: inherit;
}

blockquote *::before {
  content: ">";
}

a, a:visited {
  color: $brand-color;
  text-decoration: none !important;
}

.topnav {
  /* background-color: $brand-color; */
  background-color: $topnav-color;
  overflow: hidden;
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 0;
  left: 0;
}

.hidden_text {
  background: black;
  color: black;
}


.hidden_text:hover {
  background: black;
  color: white;
}

.topnav a {
  float: left;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  /* color: $text-color; */
  color: $background-color;
  font-size: 17px;
}

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 56px;
  padding: 54px 12px 24px;
}

.logo img {
  width: 100%;
  height: auto;
  max-width: 400pt;
}

.site-title {
  @include relative-font-size(1.625);
  font-weight: 300;
  line-height: $base-line-height * $base-font-size * 2.25;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;

  &,
  &:visited {
    color: $grey-color-dark;
  }
}

.site-nav {
  float: right;
  line-height: $base-line-height * $base-font-size * 2.25;

  .nav-trigger {
      display: none;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    right: $spacing-unit / 2;
    background-color: $background-color;
    border: 1px solid $grey-color-light;
    border-radius: 5px;
    text-align: right;

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        fill: $grey-color-dark;
      }
    }

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}



/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}



/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}


/* Video stuff */

audio,
video {
  display: inline-block;
}

.video-responsive {
  display: block;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}

.video-responsive::before {
  content: "";
  display: block;
  padding-bottom: 56.25%;
}

.video-responsive iframe,
.video-responsive object,
.video-responsive embed {
  border: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

video.video-responsive {
  height: auto;
  max-width: 100%;
}

video.video-responsive::before {
  content: none;
}

.video-responsive-4-3::before {
  padding-bottom: 75%;
}

.video-responsive-1-1::before {
  padding-bottom: 100%;
}

@media (min-width: 800px) {
	.video-container {
		padding: 0 30%;
	}
}

.post-nav {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: $spacing-unit;

    div {
      flex: 1 1 0;
      
      &:first-child {
        padding-right: 0.5em;
      }
      
      &:last-child {
        padding-left: 0.5em;
        text-align: right;
      }
    }
}

.unsighted {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}
